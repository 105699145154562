<template>
  <!-- Modal Maximum Package -->
  <app-modal
    :show="showModalMaxPackage"
    @close="showModalMaxPackage = false"
    :textButtonCancel="$t('general.close')"
  >
    <template v-slot:title>{{
      $t("subscription.package.max_packages_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("subscription.package.max_packages_description") }}
      </p>
    </template>
  </app-modal>
  <app-modal-package-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :packages="selectedPackage"
    @update:packages="selectedPackage = $event"
  />

  <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("subscription.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>
  <div>
    <app-table
      :loading="apiPaginationLoading"
      @on-item-click="onItemClick"
      :apiResponse="apiPaginationResponse"
      :showNumber="false"
      @empty-button-clicked="createPackage"
      emptyIcon="app-icon-empty-subscription"
      :emptyTitle="$t('subscription.empty.title')"
      :emptyButton="$t('subscription.create_package')"
      :emptyDescription="$t('subscription.empty.description')"
      @params-changed="paramsChanged"
      :filters="[
        {
          key: 'filter[name]',
          type: 'text',
          placeholder: $t('subscription.table_header.package_name'),
        },
        {
          key: 'filter[currency]',
          type: 'text',
          placeholder: $t('subscription.table_header.currency'),
        },
        {
          key: 'filter[amount]',
          type: 'number',
          placeholder: $t('subscription.table_header.package_amount'),
        },
        {
          key: 'filter[frequency]',
          type: 'select',
          placeholder: $t('subscription.table_header.package_term'),
          value: {},
          items: [
            {
              id: 'daily',
              name: 'Daily',
            },
            {
              id: 'weekly',
              name: 'Weekly',
            },
            {
              id: 'monthly',
              name: 'Monthly',
            },
            {
              id: 'yearly',
              name: 'Yearly',
            },
          ],
        },
      ]"
    >
      <template v-slot:title>
        <div>
          <p class="font-medium">
            {{ $t("subscription.package_list_title") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-1/8"
          :showf70Icon="false"
          @click="createPackage"
          :loading="apiPaginationLoading"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
          </template>
          {{ $t("subscription.create_package") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_name") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_amount") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_term") }}
        </th>
        <th class="font-bold">{{ $t("general.actions") }}</th>
      </template>
      <template v-slot:body="slotData">
        <td>
          {{ slotData.model?.name }}
        </td>
        <td>
          {{
            $formats.currency(
              slotData.model?.currency,
              slotData.model?.amount ?? 0.0
            )
          }}
        </td>
        <td class="capitalize">
          {{ slotData.model?.frequency_num }}
          {{ packageFrequency(slotData.model) }}
        </td>
        <td class="stopItemClick">
          <div class="flex space-x-2">
            <app-button-outline
              class="bg-white py-1"
              @click.stop="viewSubscriber(slotData.model)"
            >
              <p class="ml-1 text-sm">
                {{ $t("subscription.view_subscriber") }}
              </p>
            </app-button-outline>
            <app-button-dropdown :showArrow="true">
              <div class="py-2">
                <app-icon
                  name="DotsVerticalIcon"
                  :class="['h-5 w-5 text-gray-500 transition transform']"
                >
                </app-icon>
              </div>
              <template #menu-items>
                <app-button-dropdown-item
                  v-for="item in getActionItems(slotData.model)"
                  :key="item.key"
                  :disabled="item.disable"
                  :show="item.show"
                  class="p-3"
                  @click.stop="handleActionItemClick(item, slotData.model)"
                >
                  <div class="flex space-x-2 items-center">
                    <p>{{ item.title }}</p>
                    <app-text-tooltip v-if="item.disable" :text="item.toolTipText">
                      <app-icon-outline name="InformationCircleIcon" class="w-4 hover:text-gray-500" />
                    </app-text-tooltip>
                  </div>
                </app-button-dropdown-item>
              </template>
            </app-button-dropdown>
          </div>
        </td>
      </template>
    </app-table>
  </div>

  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{
      $t("subscription.table_header.delete_package")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("subscription.table_header.delete_package_desc") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deletePackage(selectedPackage)"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("general.delete") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import Business_status from "@/utils/const/business_status";
import PACKAGE_FREQUENCY from "@/utils/const/package_frequency";
import appModalPackageDetail from "../components/item/app-modal-package-detail.vue";
export default {
  components: { appModalPackageDetail },
  data() {
    return {
      showModalActive: false,
      showModalPackage: false,
      showModalDetail: false,
      showModalMaxPackage: false,
      showDeleteConfirmation: false,
      selectedPackage: {},
      filters: "",
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["subscriptionStore/apiPaginationResponse"];
    },
    packages() {
      return this.$store.getters["subscriptionStore/packages"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }

      return this.business.business_status_id != Business_status.APPROVED;
    },
    apiPaginationLoading() {
      return this.$store.getters["subscriptionStore/apiPaginationLoading"];
    },
  },

  mounted() {
    this.fetchListPackage();
  },

  methods: {
    async createPackage() {
      const listPackage = await this.$store.dispatch(
        "subscriptionStore/retrievePackagesByBusinessId"
      );

      if (this.$lodash.isNil(listPackage)) {
        this.$store.dispatch("subscriptionStore/resetPackage");
        this.$router.push({ name: "subscription.package.create" });
      }
      if (Object.keys(listPackage).length < 5) {
        this.$store.dispatch("subscriptionStore/resetPackage");
        this.$router.push({ name: "subscription.package.create" });
      } else {
        this.showModalMaxPackage = true;
      }
    },

    paramsChanged(filters, queryParams) {
      this.filters = queryParams;
      this.fetchListPackage();
    },

    async deletePackage(packages) {
      this.showDeleteConfirmation = false;

      const result = await this.$store.dispatch(
        "subscriptionStore/deletePackage",
        packages
      );
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
    },

    openDeleteConfirmation(selectedPackage) {
      if (!this.isEditable(selectedPackage)) {
        return;
      }
      this.selectedPackage = selectedPackage;
      this.showDeleteConfirmation = true;
    },
    onClickHasActiveBusiness() {
      this.showModalActive = !this.showModalActive;
      this.showModalPackage = !this.showModalPackage;
    },

    getActionItems(data) {
      return [
        {
          key: "edit",
          title: this.$t("subscription.edit_details"),
          disable: !this.isEditable(data),
          show: true,
          toolTipText: this.$t(
            "subscription.package.disable_action_tooltip_description",
            {
              action: this.$t("subscription.package.edit_existing_details"),
            }
          ),
        },
        {
          key: "add",
          title: this.$t("subscription.tab_subscriber"),
          disable: false,
          show: true,
          toolTipText: "",
        },
        {
          key: "delete",
          title: this.$t("subscription.delete"),
          disable: !this.isEditable(data),
          show: true,
          toolTipText: this.$t(
            "subscription.package.disable_action_tooltip_description",
            {
              action: this.$t("subscription.package.delete_package"),
            }
          ),
        },
      ];
    },

    handleActionItemClick(menu, data) {
      if (menu.key === "edit") {
        this.onClickPackage(data);
        return;
      }

      if (menu.key === "add") {
        this.$store.commit("subscriptionStore/clearSubscriber");
        this.$router.push({
          name: "subscription.subscriber.create",
          query: { package_id: data._id },
        });
        return;
      }

      if (menu.key === "delete") {
        this.openDeleteConfirmation(data);
        return;
      }
    },

    onClickPackage(selectedPackage) {
      if (!this.isEditable(selectedPackage)) {
        return;
      }
      if (selectedPackage == null) {
        this.$store.commit("subscriptionStore/initPackage");
      } else {
        this.$store.commit("subscriptionStore/setPackage", selectedPackage);
        this.$router.push({
          name: "subscription.package.create",
          query: { edit: true },
        });
      }
    },

    async fetchListPackage() {
      this.$store.dispatch("subscriptionStore/retrievePackage", this.filters);
    },

    refresh() {
      this.fetchListPackage();
    },

    isEditable(selectedPackage) {
      return selectedPackage.subscribers?.length == 0;
    },

    packageFrequency(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return data.frequency_num > 1 ? "days" : "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return data.frequency_num > 1 ? "weeks" : "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return data.frequency_num > 1 ? "months" : "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return data.frequency_num > 1 ? "years" : "year";
      }
    },

    viewSubscriber(selectedPackage) {
      this.$router.push({
        name: "subscription.subscriber.list",
        params: {
          package_id: selectedPackage._id,
          package_name: selectedPackage.name,
        },
      });
    },

    async onItemClick(data) {
      let result = await this.$store.dispatch(
        "subscriptionStore/getPackageById",
        data._id
      );
      this.selectedPackage = result.data;
      this.showModalDetail = true;
    },
  },
};
</script>